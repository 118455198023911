import React, { useEffect } from "react";
import { useQuery } from "react-query";
import AuthServices from "services/AuthServices";
import { bSdk } from "services/BackendSDKService";
import { AuthStore, updateUserData } from "stores/authStore";
import { BroadcastChannel } from "broadcast-channel";
import { getWindow, setWindow } from "utils/winUtils";
import { clearAllCaches } from "services/ReactQueryClient";

function getLoginChannel(): BroadcastChannel | null {
  if (!getWindow()) return null;
  if (!getWindow().loginChannel)
    getWindow().loginChannel = new BroadcastChannel("login");
  return getWindow().loginChannel;
}

export function useAuth() {
  const { user, isUserDataFetched, OTPObject } = AuthStore.useState(
    (state) => state
  );

  const authData = useQuery(
    "auth-query",
    async () => {
      if (AuthServices.getToken()) {
        try {
          let data = await bSdk.Auth_GetUserInfo({});
          updateUserData(data?.data);
          return data?.data;
        } catch (ex) {
          updateUserData(null);
        }
      } else {
        updateUserData(null);
      }
    },
    {
      cacheTime: 0,
      //  enabled: false  //  Removed by hamid because development was being logout on reload
    }
  );

  useEffect(() => {
    //authData.refetch(); // Removed by hamid because development was being logout on reload
    let loginChannel = getLoginChannel();
    if (!loginChannel) return;
    loginChannel.onmessage = (message: any) => {
      // console.log("login message", message);
      if (message === "logout") {
        AuthServices.onLogout({
          shouldBroadcast: false,
          logoutReason: "Session Expired",
        });
      }
      if (message.tokenData) {
        AuthServices.removeToken();
        clearAllCaches();
        AuthServices.setToken(message.token);
        updateUserData(message.tokenData);
      }
    };
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    isUserDataFetched,
    OTPObject,
  };
}
