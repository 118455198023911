import { IconButton } from "@mui/material";
import ReactQueryLoadingError from "common/custom/ReactQueryLoadingError";
import RIcon from "common/misc/RIcon";
import { CacheService } from "commonlib";
import React from "react";
import { useQuery } from "react-query";
import Utils from "services/Utils";

interface IIPContainerProps {}
export default function IPContainer(props: IIPContainerProps) {
  let q = useQuery(["public-ip"], async () => {
    return getPublicIP();
  });
  return (
    <div
      className="flex-center"
      style={{
        borderTop: "1px solid var(--primary)",
        borderBottom: "1px solid var(--primary)",
      }}
    >
      <ReactQueryLoadingError q={q} />
      {q.data && (
        <div>
          IP:
          <b>{q.data}</b>
          <IconButton
            onClick={() => {
              Utils.copyToClipboard(q.data);
            }}
          >
            <RIcon name="content_copy" />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export async function getPublicIP() {
  return CacheService.withCache(
    async () => {
      return (
        await fetch("https://api.ipify.org/?format=json").then((res) =>
          res.json()
        )
      )?.ip;
    },
    "public-ip",
    { expiresInMinutes: 60 }
  );
}
