import { Alert } from "@mui/material";
import React from "react";
import Utils from "services/Utils";

interface IErrorPlaceHolder {
  ex?: any;
  message?: string;
}

export default function ErrorPlaceHolder(props: IErrorPlaceHolder) {
  let objEx = props.ex;
  if (!props.ex && props.message) objEx = new Error(props.message);
  if (!objEx) objEx = new Error("Unexpected Error.");
  const errorString = Utils.parseErrorString(objEx);

  return (
    <div
      style={{
        maxWidth: "90vw",
        overflow: "auto",
        wordBreak: "break-all",
        margin: "8px 0px",
      }}
    >
      {objEx && <Alert severity="error">{errorString}</Alert>}
    </div>
  );
}
