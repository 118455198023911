import { useState } from "react";

export type IUseObjectStateReturn<T = any> = ReturnType<
  typeof useObjectState<T>
>;

export default function useObjectState<T = any>(defaultVal: T) {
  const [_state, _setState] = useState<T>(defaultVal);

  return {
    state: _state,
    setState: (obj: Partial<T>) => {
      _setState((oldObj) => {
        return { ...oldObj, ...obj };
      });
    },
  };
}
