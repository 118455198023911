import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import format from "date-fns/format";
import { jsPDF } from "jspdf";
import { formatInTimeZone } from "date-fns-tz";
import qs from "querystring";
import { IImageVariant, IImageVariants } from "types/common";
import { setWindow } from "utils/winUtils";

class Utils {
  static isMobile() {
    return window.innerWidth <= 600;
  }

  static isTablet() {
    return window.innerWidth <= 900;
  }

  static getFirstCharCap(word: any) {
    return word?.substr(0, 1).toUpperCase();
  }

  static isIPhone() {
    if (typeof navigator === "undefined") return false;
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  static parseQueryString(str: string) {
    return qs.parse(str.toString().replace("?", ""));
  }

  static parseToNormalDate(HHssSSS: string) {
    if (!HHssSSS) return new Date();
    const time = HHssSSS.split(":");
    return `05/02/2022, ${time[0]}:${time[1]}:${time[2]}`;
  }

  static decodeQueryObject(strSearch: string) {
    try {
      const query: any = this.parseQueryString(strSearch);
      return JSON.parse(atob(query.q));
    } catch (ex) {
      return null;
    }
  }

  static findLastIndex(array: any[], searchKey: string, searchValue: any) {
    const index = array
      .slice()
      .reverse()
      .findIndex((x) => x[searchKey] === searchValue);
    const count = array.length - 1;
    const finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
  }

  static getBase64FromDataURI(str = "") {
    let index = str?.indexOf(";base64,");
    return str.substr(index + ";base64,".length);
  }

  static readDataURIFromFile(
    file: File,
    options: {
      outputType?: "dataURI" | "binaryString";
    } = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) resolve(reader.result?.toString());
        else reject("Error occurred while reading file. Empty Result.");
      };
      reader.onerror = () => {
        reject(new Error("Error occurred while reading file"));
      };
      switch (options.outputType) {
        case "binaryString":
          reader.readAsBinaryString(file);
          break;
        case "dataURI":
        default:
          reader.readAsDataURL(file);
      }
    });
  }

  static getPercentage(value: any = 0, percentage: any = 0) {
    value = parseFloat(value) || 0;
    percentage = parseFloat(percentage) || 0;
    return (value / 100) * percentage || 0;
  }

  static parseAndFormatDate(
    date: any,
    formatString: string,
    timeZone?: string
  ) {
    if (!date) return "";
    let parseDate = new Date(date);
    if (!parseDate || parseDate?.toString() === "Invalid Date") return "";
    if (timeZone) {
      return formatInTimeZone(parseDate, timeZone, formatString);
    }
    return format(parseDate, formatString);
  }

  static convertToSystemDateFormate(date: any, timeZone?: string) {
    return this.parseAndFormatDate(date, "MM/dd/yyyy", timeZone);
  }
  static convertToMMYYYY(date: any, timeZone?: string) {
    return this.parseAndFormatDate(date, "MM/yyyy", timeZone);
  }

  static convertToYYYYMMDDFormat(date: any, useTimezone = false) {
    return this.parseAndFormatDate(date, "yyyy/MM/dd");
  }

  static convertToYYYYMMDDHHMMSSFormat(date: any) {
    return this.parseAndFormatDate(date, "YYYY/MM/DD HH:mm:ss");
  }

  static convertToLastDateFormat(date: any) {
    return this.parseAndFormatDate(date, `iii/dd MMM, yyyy`);
  }

  static convertToSystemDateTimeFormate(
    date: any,
    showSeconds = false,
    timeZone?: string
  ) {
    return this.parseAndFormatDate(
      date,
      `MM/dd/yyyy hh:mm${showSeconds ? ":ss" : ""} a`,
      timeZone
    );
  }

  static convertToHourMinuteFormate(date: any, showSeconds = false) {
    return this.parseAndFormatDate(date, `hh:mm${showSeconds ? ":ss" : ""} a`);
  }

  static convertToMonthAndYearFormat(date: any) {
    return this.parseAndFormatDate(date, `MMM yyyy`);
  }
  static convertToMonthDayAndYearFormat(date: any) {
    return this.parseAndFormatDate(date, `MMM dd,yyyy`);
  }

  static convertToDayAndDateFormat(date: any) {
    return this.parseAndFormatDate(date, `iiii/dd MMMM, yyyy`);
  }
  static convertToDayDateAndTimeFormat(date: any) {
    return this.parseAndFormatDate(date, `iiii/dd MMMM, yyyy/hh:mm a`);
  }

  // function that create error object
  static resError(err: any, message = "") {
    return {
      resType: "THT",
      status: false,
      err: err,
      message: message,
    } as any;
  }

  // function that create success object
  static resSuccess(obj: any, message = "") {
    return {
      resType: "THT",
      status: true,
      data: obj,
      message: message,
    } as any;
  }

  static 1(ary: string[], value: any = "") {
    let obj: any = {};
    ary.forEach((item) => (obj[item] = value));
    return obj;
  }
  static createObject<T extends readonly string[]>(
    steps: T
  ): Record<T[number], object> {
    const typed = {} as Record<string, string>;
    steps.forEach((step) => (typed[step] = step));
    return typed as unknown as Record<T[number], object>;
  }

  static toLowerCaseSafe(str = "") {
    return str ? str.toLowerCase() : "";
  }

  static toUpperCaseSafe(str = "") {
    return str ? str.toUpperCase() : "";
  }

  static getLastDigit(str: string, lastDigit: number = 4) {
    return str?.substr(str.length - lastDigit, lastDigit) || "";
  }

  static toFixedNumber(n: any, decimal: number = 3) {
    if (parseFloat(n)) {
      return parseFloat(parseFloat(n).toFixed(decimal));
    }
    return n;
  }

  static tryJSONParse(string: string) {
    try {
      return JSON.parse(string);
    } catch (ex) {
      return null;
    }
  }

  static parseErrorString(error: any) {
    if (typeof error === "string") {
      return error;
    }
    if (typeof error?.response?.data?.message === "string")
      return error?.response?.data?.message;

    if (typeof error.message === "string") {
      return error.message;
    }
    return error.toString();
  }

  static transformFirebaseResult(ref: any) {
    if (Array.isArray(ref.docs))
      return ref.docs.map((item: any) => ({ id: item.id, ...item.data() }));
    else if (typeof ref?.data === "function")
      return { id: ref.id, ...ref.data() };
    return null;
  }

  static askForFile(
    options: { multiple?: boolean; accept?: string } = {}
  ): Promise<FileList | null> {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.type = "file";
      if (options.multiple) input.setAttribute("multiple", "true");
      if (options.accept) input.setAttribute("accept", options.accept);
      input.onchange = () => {
        resolve(input.files);
      };

      input.click();
    });
  }

  static htmlToText(myHTML: string) {
    let strippedHtml = myHTML.replace(/<[^>]+>/g, "");
    return strippedHtml;
  }

  static toUpperUnderscore(str?: any) {
    if (str) return str?.replace(/[^a-zA-Z0-9]/g, "_")?.toUpperCase() || "";
    return "";
  }

  static checkIfArrayHasUniqueIDs(myArrayObj: any) {
    const idAry = myArrayObj.map((item: any) => item.id);

    return idAry.length === new Set(idAry).size;
  }

  static checkIfIdAlreadyExist(id: string, array?: any[]) {
    return array?.map((item) => item._id).includes(id);
  }

  static getImageURL(obj?: IImageVariant, variant: IImageVariants = "medium") {
    if (!obj) return "";
    if ((obj as any)[variant]) return (obj as any)[variant];
    return obj?.url || "";
  }

  static fireEvent(
    eventName: string,
    data: any = {},
    sendResponse?: (data?: any) => void
  ) {
    let event = new CustomEvent(eventName, { detail: { data, sendResponse } });
    document.dispatchEvent(event);
    return event;
  }

  static dateToAgo(dateValue?: string | Date) {
    try {
      let date = dateValue ? new Date(dateValue) : null;
      if (date)
        return formatDistanceToNow(date, {
          addSuffix: true,
          includeSeconds: true,
        });
      return "";
    } catch (ex) {
      return "";
    }
  }

  static stringToColor(str: any) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }

  static showDataOrNA(data: any) {
    return data ? data : "N/A";
  }
  static prettyPrice(amount: any) {
    if (!amount) amount = 0;
    if (!amount.toFixed) {
      amount = parseFloat(amount as any);
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  static showNumber(amount: number) {
    if (!amount) amount = 0;
    if (!amount.toFixed) {
      amount = parseFloat(amount as any);
    }
    return amount.toLocaleString();
  }

  static sortArrayOfObject(
    ary: any[],
    property: string,
    order: "asc" | "desc" = "asc"
  ) {
    return ary.sort((a, b) => {
      if (a[property] > b[property]) {
        return order === "asc" ? 1 : -1;
      } else if (a[property] < b[property]) return order === "asc" ? -1 : 1;
      else return 0;
    });
  }

  static getContrastText(theme: any, color: any) {
    try {
      return theme.palette.getContrastText(color || "#FFFFFF");
    } catch (ex) {
      return "#000000";
    }
  }

  static capitalizeFirstLetter(str?: string) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  static firstUppercaseAndRestLowercase(str?: string) {
    if (!str) return "";
    let strAry = str.split(" ");
    for (let index = 0; index < strAry.length; index++) {
      strAry[index] = this.capitalizeFirstLetter(strAry[index]);
    }
    return strAry.join(" ");
  }

  static copyToClipboard(text: any) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    let container = document.activeElement || document.body;
    container.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let status = false;
    try {
      status = document.execCommand("copy");
    } catch (err) {
      status = false;
    }

    container.removeChild(textArea);
    return status;
  }
  static async getContentOfClipboard() {
    try {
      const text = await navigator.clipboard.readText();
      return text;
    } catch (ex) {
      return false;
    }
  }

  static convertHoursMinTODate(timeString: any) {
    const today = new Date();
    if (!timeString) return new Date(today.setHours(8));
    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(3, 5);

    return new Date(today.setHours(hours, minutes));
  }

  static getURLWithCredentials(io: {
    URL: string;
    Credentials: object;
    Type: "SalesTax" | "TourismTax" | "PMSPOS";
    Vender?: string;
  }) {
    if (!io.URL) return "";
    let url = `${io.URL}`;
    let obj = { Type: io.Type, Vender: io.Vender, ...io.Credentials };
    if (typeof localStorage !== undefined && localStorage.aaHelperExists) {
      url += `#cred=${btoa(JSON.stringify(obj))}`;
    }
    return url;
  }

  static async getArrayBufferFromUrl(url: string) {
    let result = await axios.get(url, {
      responseType: "arraybuffer",
    });

    return result.data;
  }

  static getExcelRowColGrid(maxCol: any) {
    if (!maxCol) return [];
    let maxDigit = maxCol.split(/[^\d]+/).join("");
    let maxCharacter = maxCol.split(/[\W\d]+/).join("");
    let obj: any = {};

    for (let ascii = 65; String.fromCharCode(ascii) <= maxCharacter; ascii++) {
      obj[String.fromCharCode(ascii)] = " ";
      // console.log(obj);
    }

    let lstResult = Array(Number(maxDigit))
      .fill("")
      .map(() => ({ ...obj }));

    return lstResult;
  }

  static getExcelTableArray(objRowCol: any) {
    if (!objRowCol) return [];
    let maxRowCall = "";
    const cellRange = objRowCol["!ref"];
    const regex = /([A-Z]+)(\d+):([A-Z]+)(\d+)/;
    const match = cellRange.match(regex);

    if (match) {
      const endRow = match[4];
      let endCol = match[3];
      if (endCol.length > 1) endCol = "Z"; // Limit to Z Change this to have more column then Z
      maxRowCall = `${endCol}${endRow}`;
    }

    let lstGrid = this.getExcelRowColGrid(maxRowCall);

    for (const key in objRowCol) {
      if (key === "!ref") continue;
      let currentDigit = key.split(/[^\d]+/).join("");
      let currentCharacter = key.split(/[\W\d]+/).join("");
      const element = objRowCol[key];

      if (
        currentDigit &&
        lstGrid[Number(currentDigit) - 1] &&
        lstGrid[Number(currentDigit) - 1][currentCharacter]
      ) {
        lstGrid[Number(currentDigit) - 1][currentCharacter] =
          element.w || element.v;
      }
    }

    return lstGrid;
  }

  static displayMoneyORDash(value: any) {
    if (!value && value !== 0) {
      return "-";
    } else {
      return this.prettyPrice(value);
    }
  }

  static displayValueORDash(value: any) {
    if (!value && value !== 0) {
      return "-";
    } else {
      return value;
    }
  }

  static printHTML(
    printContents: string,
    options?: { includeAllStyles?: boolean }
  ) {
    let iframe = document.createElement("iframe");
    // iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);
    let doc = iframe.contentWindow?.document;
    if (!doc) throw new Error(`Document not found`);
    let strStyle = "";
    if (options?.includeAllStyles) {
      let styles = Array.from(document.querySelectorAll("style"));
      strStyle = styles.map((style) => style.outerHTML).join("");
      console.log(styles.length);
    }
    doc.body.innerHTML = `<html><head>
    <style>
      @media print {
        @page { margin: 0mm; }
        body {-webkit-print-color-adjust: exact;}
        body { margin: 0.1cm; }
      }
    </style>
    ${strStyle}
    </head><body>${printContents}</body></html>`;

    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();
    console.log("Printed");

    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 5000);
  }
  static async printHTMLWithJSPDF(
    element: HTMLElement,
    options: {
      output: "print" | "download" | "datauri";
      fileName?: string;
    }
  ) {
    let pWidth = 595.28; // for a4 size
    let srcWidth = element.scrollWidth;
    let margin = 18; // narrow margin - 1.27 cm (36);
    let scale = (pWidth - margin * 2) / srcWidth;
    var doc = new jsPDF("p", "pt", "a4");

    await doc.html(element.outerHTML, {
      ...({ x: margin, y: margin } as any),
      html2canvas: {
        scale: scale,
      },
    });

    if (options?.output === "download") {
      // doc.output("dataurlnewwindow");
      doc.save(options.fileName || "download.pdf");
    } else if (options?.output === "datauri")
      return await doc.output("datauristring");
    else if (options?.output === "print") {
      let uri = (await doc.output("bloburl")) as any;
      let iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);
      iframe.src = uri;
      iframe.onload = () => {
        iframe.contentWindow?.focus();
        iframe.contentWindow?.print();
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 60000);
      };
    }
  }

  static async wait(timeout: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, timeout);
    });
  }

  static async waitForFunction(
    fn: () => boolean | Promise<boolean>,
    timeout: number,
    options: { interval: number } = {
      interval: 1000,
    }
  ) {
    let interval = options.interval;
    let count = 0;
    while (count < timeout) {
      if (await fn()) return true;
      count += interval;
      await this.wait(interval);
    }
    return false;
  }

  static escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  static camelCaseToTitleCase(text: string) {
    const result = text?.replace(/([A-Z])/g, " $1");
    if (!result) return text;
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
}

export default Utils;
setWindow("Utils", Utils);
