import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";
import HTextField from "components/inputs/hookInputs/HTextField";
import { AlertUtils } from "hyper-utils";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { bSdk } from "services/BackendSDKService";
import * as yup from "yup";

interface IFormType {
  CurrentPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
}

interface IChangePasswordProps {
  onComplete?: () => void;
}
export default function ChangePassword(props: IChangePasswordProps) {
  const objForm = useForm<IFormType>({
    defaultValues: {
      CurrentPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
    },
    resolver: yupResolver(
      yup.object({
        CurrentPassword: yup.string().required("Current password is required"),
        NewPassword: yup
          .string()
          .required("New password is required")
          .min(8, "Password must be at least 8 characters"),
        ConfirmPassword: yup
          .string()
          .oneOf([yup.ref("NewPassword"), null], "Passwords must match"),
      })
    ),
  });

  const action = useMutation(async (data: IFormType) => {
    await bSdk.Auth_ChangePassword(data);
    AlertUtils.showSuccess(`Password changed successfully. `);
    props.onComplete?.();
  });

  const onSubmit = (data: IFormType) => {
    action.mutate(data);
  };

  return (
    <div>
      <form onSubmit={objForm.handleSubmit(onSubmit)}>
        <FormProvider {...objForm}>
          <Grid container>
            <Grid item xs={12}>
              <HTextField
                name="CurrentPassword"
                componentProps={{
                  label: "Current Password",
                  type: "password",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <HTextField
                name="NewPassword"
                componentProps={{
                  label: "New Password",
                  type: "password",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <HTextField
                name="ConfirmPassword"
                componentProps={{
                  label: "Confirm New Password",
                  type: "password",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton loading={action.isLoading} type="submit">
                Submit
              </LoadingButton>
              {action.isError && <ErrorPlaceHolder ex={action.error} />}
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </div>
  );
}
