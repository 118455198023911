import { LocalStorageUtils } from "hyper-utils";
import { getWindow } from "utils/winUtils";

const config = {
  get ROOT_URL() {
    return (
      LocalStorageUtils.lsGet("ROOT_URL") ||
      process.env.NEXT_PUBLIC_ROOT_URL ||
      ""
    );
  },
  get LOGBOOK_URL() {
    return process.env.NEXT_PUBLIC_LOGBOOK_URL;
  },
  IS_DEVELOPMENT: process.env.NEXT_PUBLIC_IS_DEVELOPMENT !== "false",
  SERVER_TIMEZONE:
    process.env.NEXT_PUBLIC_SERVER_TIMEZONE || "America/New_York",
  C_LOCK_SCREEN_AFTER_MINUTES:
    parseInt(process.env.NEXT_PUBLIC_C_LOCK_SCREEN_AFTER_MINUTES || "0") || 60,
  C_LOGOUT_IN_MINUTES_AFTER_SCREEN_LOCK:
    parseInt(
      process.env.NEXT_PUBLIC_C_LOGOUT_IN_MINUTES_AFTER_SCREEN_LOCK || "0"
    ) || 30,
  TAWK_API_KEY: process.env.TAWK_API_KEY,
};

export default config;

if (getWindow()) {
  getWindow().useLive = () => {
    LocalStorageUtils.lsSet(
      "ROOT_URL",
      "https://backend.advanceautomation.tech"
    );
  };
  getWindow().useDev = () => {
    LocalStorageUtils.lsDelete("ROOT_URL");
  };
}
