export function getError(name: string, objForm: any) {
  if (objForm?.formState?.errors[name])
    return objForm?.formState?.errors[name]?.message?.toString();
  try {
    let value = getDescendantProp(objForm?.formState?.errors, name);
    if (value) return value?.message?.toString();
  } catch (ex) {
    console.debug("Error occurred while reading error from form. ", name, ex);
  }

  return "";
}

function getDescendantProp(obj: any, desc: any) {
  var arr = desc.split(".");
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
}
