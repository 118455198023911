import { Paper } from "@mui/material";
import ChangePassword from "components/my-account/ChangePassword";
import { refetchQuery } from "services/ReactQueryClient";

interface IFormType {
  Username: string;
}

interface IChangePasswordForExpiredPasswordProps {
  onComplete?: () => void;
}
export default function ChangePasswordForExpiredPassword(
  props: IChangePasswordForExpiredPasswordProps
) {
  return (
    <div className="flex-center" style={{ height: "100vh" }}>
      <Paper style={{ padding: 15 }}>
        <div>
          <h2>Password Expired</h2>
          <p>
            Your password has expired. To access the system, please update your
            password to meet the following criteria:
          </p>
          <ul>
            <li>
              <strong>Minimum Length</strong>: At least 12 characters long.
            </li>
            <li>
              <strong>Letters</strong>: Must include both uppercase and
              lowercase letters.
            </li>
            <li>
              <strong>Numbers and Special Characters</strong>: Must include at
              least one number and one special character (e.g., !, @, #, $, %,
              ^, &, *).
            </li>
          </ul>
        </div>

        <ChangePassword
          onComplete={() => {
            refetchQuery("auth-query");
          }}
        />
      </Paper>
    </div>
  );
}
