import { useEffect } from "react";
import { bSdk } from "services/BackendSDKService";
import { AuthStore } from "stores/authStore";
import { getPublicIP } from "./IPBox";

export default function NavigationListener() {
  useEffect(() => {
    // This listener is not required any more. Logs are being handled from MainLayout
    // Router.events.on("routeChangeComplete", async () => {
    //   try {
    //     CreateUserAccessHistory();
    //   } catch (ex) {
    //     console.log(
    //       `Error occurred while adding navigation history. ${parseErrorString(
    //         ex
    //       )}`
    //     );
    //   }
    // });
  }, []);
  return null;
}

export async function CreateUserAccessHistory() {
  let publicIP = await getPublicIP();
  // console.log("publicIP", publicIP);
  let pageTitle = document.title.split("-")[0]?.toString();

  let objAuth = AuthStore.getRawState();

  let obj = {
    PageURL: window.location.href,
    PageTitle: pageTitle,
    PublicIP: publicIP,
    ActiveMasterClientID: objAuth.selectedMasterClient?.MasterClientID,
  };

  await bSdk.cmsCreate("user-access-history", obj);
}
