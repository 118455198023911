import React from "react";

export default function ProductionModeRibbon() {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "preview")
    return (
      <div className="ribbon ribbon-top-left">
        <span>Stage</span>
      </div>
    );
  return null;
}
