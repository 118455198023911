import { BackendSDK } from "backendsdk";
import { CommonLibUtils } from "commonlib";
import config from "config";
import { setWindow } from "utils/winUtils";
import AuthServices from "./AuthServices";
import { SocketClientFrontend } from "./SocketClientFrontend";

export const bSdk = new BackendSDK({
  rootURL: config.ROOT_URL,
  getAuthToken: async () => {
    if (
      config.IS_DEVELOPMENT &&
      SocketClientFrontend.firstTimeConnected &&
      !SocketClientFrontend.isConnected
    ) {
      await CommonLibUtils.waitForFunction(() => {
        return SocketClientFrontend.isConnected;
      }, 10000);
    }
    return AuthServices.getToken();
  },
});

setWindow("bSdk", bSdk);
