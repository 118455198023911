import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ReactQueryLoadingError from "common/custom/ReactQueryLoadingError";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";
import ErrorPage from "common/misc/ErrorPage";
import RIcon from "common/misc/RIcon";
import {
  CommonDateUtils,
  CommonLibUtils,
  DOMUtils,
  ILoginResponse,
  ITFAOTPObject,
} from "commonlib";
import HTextField from "components/inputs/hookInputs/HTextField";
import config from "config";
import useObjectState from "hooks/useObjectState";
import { useRouter } from "next/router";
import { FormProvider, useForm } from "react-hook-form";
import { Case, Switch } from "react-if";
import { useMutation } from "react-query";
import AuthServices from "services/AuthServices";
import { bSdk } from "services/BackendSDKService";
import { AuthStore, SetOTPObject, updateUserData } from "stores/authStore";
import { IFormInputs } from "types/user";
import { getWindow } from "utils/winUtils";
import * as yup from "yup";
import Countdown from "react-countdown";
import { LocalStorageUtils } from "hyper-utils";
import useServerEvent from "hooks/useServerEvent";
import { SocketClientFrontend } from "services/SocketClientFrontend";

const schema = yup
  .object({
    Username: yup.string().required("Username is Required"),
    Password: yup.string().required("Password is Required"),
  })
  .required();

export default function TFAOTPScreen(props: { OTPObject: ITFAOTPObject }) {
  const { state, setState } = useObjectState({
    capsLock: false,
    Stage: "CHOOSE_METHOD", // CHOOSE_METHOD|VERIFY_OTP
    OTPMethod: "", // Email| Mobile
    ResendTime: 0,
  });

  useServerEvent(
    [`LOGIN_OTP_${SocketClientFrontend?.client?.id}`],
    (obj) => {
      if (!SocketClientFrontend?.client?.id || !obj?.message) return;
      DOMUtils.val("#txtOTP", obj.message);
      otpAction.mutate();
    },
    {
      dependency: [SocketClientFrontend?.client?.id],
    }
  );

  let sendOTPAction = useMutation(async (Method: string) => {
    let result = await bSdk.Auth_SendTFAOTP({
      OTPToken: props.OTPObject.OTPToken,
      SendViaEmail: Method === "Email",
      SendViaMobile: Method === "Mobile",
      SocketConnectionID: SocketClientFrontend?.client?.id,
    });

    AuthStore.update((s) => {
      s.OTPObject = {
        ...(s.OTPObject as any),
        OTPToken: result.data.OTPToken,
      };
    });
    setState({
      OTPMethod: Method,
      Stage: "VERIFY_OTP",
      ResendTime: Date.now() + 120000,
    });
  });

  let otpAction = useMutation(async () => {
    if (!DOMUtils.val("#txtOTP")) {
      throw new Error(`Please Enter Verification Code`);
    }

    let response = await bSdk.Auth_VerifyTFAOTP({
      ActualOTP: DOMUtils.val("#txtOTP") || "",
      OTPToken: props.OTPObject.OTPToken,
    });

    let loginResponse: ILoginResponse = response.data;
    AuthServices.setToken(loginResponse.token);
    updateUserData(loginResponse.tokenData);
    SetOTPObject(null);
    if (getWindow()?.loginChannel) {
      getWindow().loginChannel.postMessage(loginResponse);
    }
  });

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormInputs) => {};

  if (!props.OTPObject) {
    return <ErrorPage ex="There is no otp object. " />;
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        lg={9}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random/?nature)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        lg={3}
        component={Paper}
        elevation={6}
        // square
      >
        <FormProvider {...methods}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar src="/images/logo.png" sx={{ width: 150, height: 150 }} />
            <Typography
              component="h1"
              variant="h5"
              color="primary"
              sx={{ my: 5, fontWeight: "bold" }}
            >
              Two Factor Authentication
            </Typography>
            <ReactQueryLoadingError q={sendOTPAction} />
            <Switch>
              <Case condition={state.Stage === "CHOOSE_METHOD"}>
                <Grid container>
                  <Grid item xs={12}>
                    The system needs to verify your access using Two Factor
                    Authentication. Please select one of the options available
                    below to receive the Verification Code.
                  </Grid>
                  <Grid item xs={12}>
                    {!props.OTPObject.TFAEmail &&
                      !props.OTPObject.TFAMobile && (
                        <div>
                          There is no valid method to send Verification Code.{" "}
                        </div>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    {props.OTPObject.TFAEmail && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          sendOTPAction.mutate("Email");
                        }}
                      >
                        <div>
                          Click Here Receive Verification Code on your email{" "}
                          <b>{props.OTPObject.TFAEmail}</b>
                        </div>
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {props.OTPObject.TFAMobile && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          sendOTPAction.mutate("Mobile");
                        }}
                      >
                        <div>
                          Click Here Receive Verification Code on your Mobile
                          Phone <b>{props.OTPObject.TFAMobile}</b>
                        </div>
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        SetOTPObject(null);
                      }}
                    >
                      <RIcon name="arrow_back" /> Go Back
                    </Button>
                  </Grid>
                </Grid>
              </Case>
              <Case condition={state.Stage === "VERIFY_OTP"}>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid item xs={12}>
                    <div>
                      Enter verification code you received on &nbsp;
                      <b>
                        {state.OTPMethod === "Email" &&
                          props.OTPObject.TFAEmail}
                      </b>
                      <b>
                        {state.OTPMethod === "Mobile" &&
                          props.OTPObject.TFAMobile}
                      </b>
                    </div>
                    {state.OTPMethod === "Email" && (
                      <div>
                        Please check spam folder if you don't find it in your
                        primary Inbox.
                      </div>
                    )}
                  </Grid>
                  {state.ResendTime && (
                    <Grid item xs={12}>
                      Did not receive yet &nbsp;
                      <Countdown
                        date={state.ResendTime}
                        key={state.ResendTime}
                        renderer={(counter) => {
                          if (counter.completed) {
                            return (
                              <Button
                                onClick={() => {
                                  sendOTPAction.mutate(state.OTPMethod);
                                }}
                              >
                                Resend
                              </Button>
                            );
                          }
                          return (
                            <Button disabled>
                              Resend ({counter.minutes}:{counter.seconds})
                            </Button>
                          );
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      label="Verification Code"
                      style={{ width: 150 }}
                      id="txtOTP"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        otpAction.mutate();
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        setState({
                          Stage: "CHOOSE_METHOD",
                        });
                      }}
                    >
                      <RIcon name="arrow_back" /> Choose Different Method
                    </Button>
                  </Grid>
                </Grid>
                <ReactQueryLoadingError q={otpAction} />
              </Case>
            </Switch>
          </Box>
        </FormProvider>
      </Grid>
    </Grid>
  );
}
