import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import RIcon from "common/misc/RIcon";
import { Store } from "pullstate";
import React, { ComponentProps, ReactNode } from "react";
import Draggable from "react-draggable";
import { createUUID } from "utils/uuidUtils";

const ConfirmStore = new Store<{ modals: IConfirmModalProps[] }>({
  modals: [],
});

export function ConfirmModalPanel() {
  let state = ConfirmStore.useState();
  return (
    <>
      {state.modals.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item.open && <ConfirmModal {...item} />}
          </React.Fragment>
        );
      })}
    </>
  );
}

/**
 * @category Frontend
 * @subcategory Modal
 * @description This method should be used to show confirm dialog. with Confirm and Cancel button.
 * @param io - (refer `IConfirmModalProps`) This is the props for the dialog.
 * @returns {Promise<boolean>} - This returns a promise that resolves to true if the user clicks confirm and false if the user clicks cancel.
 */
export function showConfirmDialog(
  io: Omit<IConfirmModalProps, "open" | "onComplete" | "id"> & {
    dialogProps?: Partial<ComponentProps<typeof Dialog>>;
  }
) {
  return new Promise((resolve, reject) => {
    showDialog({
      ...io,
      isConfirm: true,
      onComplete: (confirm) => {
        resolve(confirm);
      },
    });
  });
}

/**
 * @category Frontend
 * @subcategory Modal
 * @description
 * - This method should be used to show any type of modal.
 * - <b>Note: </b> This method is used to show any type of modal. If you want to show confirm dialog, use `showConfirmDialog` method.
 * - Main advantage of this method over traditional react modals is that, You don't need to pass `open` state and conditionally render your component.
 * - You can show modal anywhere without using any hook directly.
 * - You can just call this method and it will return an object with `toggle` method to show/hide modal and `update` method to update modal props.
 * @param io - (refer `IConfirmModalProps`) This is the props for the dialog.
 * @example
 * ```tsx
 * let modal = showDialog({
 *  body: <div>Hello</div>
 * })
 *
 * // To close modal
 * modal.toggle(false);
 *
 * // To update modal props
 * modal.update({
 *  body: <div>Hello</div>
 * })
 * ```
 * @returns {Promise<boolean>} - It returns an object with `toggle` method to show/hide modal and `update` method to update modal props.
 */
export function showDialog(
  io: Omit<IConfirmModalProps, "open" | "onComplete" | "id"> & {
    dialogProps?: Partial<ComponentProps<typeof Dialog>>;
    onComplete?: (confirm: boolean) => void;
  }
) {
  let id = createUUID();
  let initialProps = {
    open: true,
    id,
    dialogProps: io.dialogProps as any,
    ...io,
    onComplete: (confirm?: boolean) => {
      ConfirmStore.update((s) => {
        s.modals = s.modals.filter((item) => item.id !== id);
      });
      if (io.onComplete) io.onComplete(confirm || false);
    },
  };
  ConfirmStore.update((s) => {
    s.modals.push(initialProps);
  });
  return {
    toggle: (open?: boolean) => {
      ConfirmStore.update((s) => {
        s.modals = s.modals.map((item) => {
          if (item.id === id) {
            item.open = open || !item.open;
          }
          return item;
        });
      });
    },
    update: (io: Partial<IConfirmModalProps>) => {
      ConfirmStore.update((s) => {
        s.modals = s.modals.map((item) => {
          if (item.id === id) {
            item = { ...item, ...io } as any;
          }
          return item;
        });
      });
    },
  };
}

/**
 * @category Frontend
 * @subcategory Modal
 * @description This is the props for the confirm modal.
 */
interface IConfirmModalProps {
  /** This is the id of the modal. used to identify modal globally. Need not to be provided when using `showConfirmDialog` or `showDialog`   */
  id: string;
  /** This is the title of the modal. */
  title?: ReactNode;
  /** This is the body of the modal. You can render `JSX` here. You can also render Textfield inside body. and then take user input.
   * So, It can also be used as prompt dialog with multiple input fields.
   * @example
   * ```tsx
   * let shouldOverride = false;
   * showDialog({
   *  body: <div>
   *  <Checkbox
   *    onChange={(e) => {
   *      // Set the value of shouldOverride to the checked state of the checkbox
   *      shouldOverride = e.target.checked;
   *    }}
   *  />
   *  <Button
   *    onClick={() => {
   *      // Use the value of shouldOverride
   *    }}
   *  >
   *    Confirm
   *  </Button>
   *  </div>
   * })
   * ```
   */
  body?: ReactNode;
  /** This is the props for the dialog. Same as MUI `Dialog` Component props. */
  dialogProps?: Partial<ComponentProps<typeof Dialog>>;
  /** This is the open state of the modal. Need not to be provided when using `showConfirmDialog` or `showDialog` */
  open: boolean;
  /** This is the confirm state of the modal. If true, the modal will show confirm and cancel button. */
  isConfirm?: boolean;
  /** This is the callback function that will be called when the user clicks confirm or cancel. need not to be provided when using `showConfirmDialog` or `showDialog` */
  onComplete: (confirm: boolean) => void;
  /** This is the draggable state of the modal. If true, the modal will be draggable. */
  isDraggable?: boolean;
}
export function ConfirmModal(props: IConfirmModalProps) {
  // console.log(props);
  return (
    <>
      <Draggable handle=".draggable-handle" disabled={!props.isDraggable}>
        <Dialog
          open={props.open}
          maxWidth={"sm"}
          fullWidth
          onClose={() => {
            if (!props.isConfirm) {
              props.onComplete(false);
            }
          }}
          hideBackdrop={props.isDraggable}
          {...props.dialogProps}
          sx={{
            ".MuiPaper-root": {
              border: props.isDraggable
                ? "3px solid var(--primary)"
                : undefined,
            },
            ".draggable-handle": {
              cursor: props.isDraggable ? "pointer" : undefined,
            },
          }}
        >
          {props.open && (
            <>
              {props.title && (
                <DialogTitle
                  style={{ padding: 5, paddingLeft: 8 }}
                  className="draggable-handle"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {props.title}
                    <div style={{ flexGrow: 1 }}></div>
                    {!props.isConfirm && (
                      <IconButton
                        style={{ backgroundColor: "white" }}
                        onClick={() => {
                          props.onComplete(false);
                        }}
                      >
                        <RIcon name="close" />
                      </IconButton>
                    )}
                  </div>
                </DialogTitle>
              )}
              {props.body && (
                <DialogContent className="model-content" style={{ padding: 8 }}>
                  {props.body}
                </DialogContent>
              )}
              {props.isConfirm && (
                <DialogActions className="model-content">
                  <>
                    <Button
                      color="primary"
                      onClick={() => {
                        props.onComplete(true);
                      }}
                      autoFocus
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => {
                        props.onComplete(false);
                      }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </>
                </DialogActions>
              )}
            </>
          )}
        </Dialog>
      </Draggable>
    </>
  );
}
