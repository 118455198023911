const colorList = {
  names: [
    "Red",
    "Pink",
    "Purple",
    "DeepPurple",
    "Indigo",
    "Blue",
    "LightBlue",
    "Cyan",
    "Teal",
    "Green",
    "LightGreen",
    "Lime",
    "Yellow",
    "Amber",
    "Orange",
    "DeepOrange",
    "Brown",
    "Grey",
    "BlueGrey",
  ],
  light: [
    "#ef9a9a",
    "#f48fb1",
    "#ce93d8",
    "#b39ddb",
    "#9fa8da",
    "#90caf9",
    "#cddde4",
    "#80deea",
    "#80cbc4",
    "#a5d6a7",
    "#c5e1a5",
    "#e6ee9c",
    "#fff59d",
    "#ffe082",
    "#ffcc80",
    "#ffab91",
    "#bcaaa4",
    "#eeeeee",
    "#b0bec5",
  ],
  dark: [
    "#b71c1c",
    "#880e4f",
    "#4a148c",
    "#311b92",
    "#1a237e",
    "#0d47a1",
    "#01579b",
    "#006064",
    "#004d40",
    "#1b5e20",
    "#33691e",
    "#827717",
    "#f57f17",
    "#ff6f00",
    "#e65100",
    "#bf360c",
    "#3e2723",
    "#212121",
    "#263238",
  ],
  medium: [
    "#e53935",
    "#d81b60",
    "#8e24aa",
    "#5e35b1",
    "#3949ab",
    "#1e88e5",
    "#039be5",
    "#00acc1",
    "#00897b",
    "#43a047",
    "#7cb342",
    "#c0ca33",
    "#fdd835",
    "#ffb300",
    "#fb8c00",
    "#f4511e",
    "#6d4c41",
    "#757575",
    "#546e7a",
  ],
  Red: {
    light: "#ef9a9a",
    dark: "#b71c1c",
    medium: "#e53935",
  },
  Pink: {
    light: "#f48fb1",
    dark: "#880e4f",
    medium: "#d81b60",
  },
  Purple: {
    light: "#e8acf2",
    dark: "#4a148c",
    medium: "#8e24aa",
  },
  DeepPurple: {
    light: "#b39ddb",
    dark: "#311b92",
    medium: "#5e35b1",
  },
  Indigo: {
    light: "#9fa8da",
    dark: "#1a237e",
    medium: "#3949ab",
  },
  Blue: {
    light: "#90caf9",
    dark: "#0d47a1",
    medium: "#1e88e5",
  },
  LightBlue: {
    light: "#cddde4",
    dark: "#01579b",
    medium: "#039be5",
  },
  Cyan: {
    light: "#80deea",
    dark: "#006064",
    medium: "#00acc1",
  },
  Teal: {
    light: "#80cbc4",
    dark: "#004d40",
    medium: "#00897b",
  },
  Green: {
    light: "#a5d6a7",
    dark: "#1b5e20",
    medium: "#43a047",
  },
  LightGreen: {
    light: "#c5e1a5",
    dark: "#33691e",
    medium: "#7cb342",
  },
  Lime: {
    light: "#e6ee9c",
    dark: "#827717",
    medium: "#c0ca33",
  },
  Yellow: {
    light: "#fff59d",
    dark: "#f57f17",
    medium: "#fdd835",
  },
  Amber: {
    light: "#ffe082",
    dark: "#ff6f00",
    medium: "#ffb300",
  },
  Orange: {
    light: "#fcd395",
    dark: "#e65100",
    medium: "#fb8c00",
  },
  DeepOrange: {
    light: "#ffab91",
    dark: "#bf360c",
    medium: "#f4511e",
  },
  Brown: {
    light: "#bcaaa4",
    dark: "#3e2723",
    medium: "#6d4c41",
  },
  Grey: {
    light: "#eeeeee",
    dark: "#212121",
    medium: "#757575",
  },
  BlueGrey: {
    light: "#b0bec5",
    dark: "#263238",
    medium: "#546e7a",
  },

  Apricot: {
    light: "#FDEBDF",
    dark: "#FAAE82",
    medium: "#fbceb1",
  },
  Mint: {
    dark: "#08a15b",
    medium: "#42db95",
    light: "#ADEFD1",
  },
  SailorBlue: {
    dark: "#00203FFF",
    medium: "#2885dd",
    light: "#9ac7f1",
  },
};

export default colorList;
