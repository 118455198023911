import { darken, lighten, Theme } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { createStyles, makeStyles } from "@mui/styles";
import colorList from "./colors";

export default function CssVars() {
  const classes = useStyles();
  return null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      //Global Classes
      ".bg-gradient": {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} , ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText,
      },
      ".bg-gradient-indigo": {
        background: `linear-gradient(to right, ${colorList.Indigo.dark} , ${colorList.Indigo.light})`,
        color: theme.palette.getContrastText(colorList.Indigo.medium),
      },
      ".bg-gradient-amber": {
        background: `linear-gradient(to right, ${darken(
          colorList.Amber.medium,
          0.2
        )} , ${lighten(colorList.Amber.medium, 0.2)})`,
        color: theme.palette.getContrastText(colorList.Amber.medium),
      },
      ".bg-gradient-teal": {
        background: `linear-gradient(to right, ${darken(
          colorList.Teal.medium,
          0.2
        )} , ${lighten(colorList.Teal.medium, 0.2)})`,
        color: theme.palette.getContrastText(colorList.Teal.medium),
      },
      ".bg-gradient-lime": {
        background: `linear-gradient(to right, ${darken(
          colorList.Lime.medium,
          0.2
        )} , ${lighten(colorList.Lime.medium, 0.2)})`,
        color: theme.palette.getContrastText(colorList.Lime.medium),
      },
      ".bg-gradient-deepPurple": {
        background: `linear-gradient(to right, ${darken(
          colorList.DeepPurple.medium,
          0.2
        )} , ${lighten(colorList.DeepPurple.medium, 0.2)})`,
        color: theme.palette.getContrastText(colorList.DeepPurple.medium),
      },
      ".bg-gradient-brown": {
        background: `linear-gradient(to right, ${darken(
          colorList.Brown.medium,
          0.2
        )} , ${lighten(colorList.Brown.medium, 0.2)})`,
        color: theme.palette.getContrastText(colorList.Brown.medium),
      },
      ".bg-gradient-blueGrey": {
        background: `linear-gradient(to right, ${darken(
          colorList.BlueGrey.medium,
          0.2
        )} , ${lighten(colorList.BlueGrey.medium, 0.2)})`,
        color: theme.palette.getContrastText(colorList.BlueGrey.medium),
      },
      ".bg-gradient-next": {
        background: `linear-gradient(to right, ${darken(
          colorList.LightGreen.medium,
          0.2
        )} , ${lighten(colorList.LightGreen.medium, 0.2)})`,
        color: theme.palette.getContrastText(colorList.LightGreen.medium),
      },
      ".text-primary-contrast": {
        color: theme.palette.primary.contrastText,
      },
      ":root": {
        ...{
          "--primary": theme.palette.primary.main,
          "--primary-light": theme.palette.primary.light,
          "--secondary": theme.palette.secondary.main,
          "--error": theme.palette.error.main,
          "--bg-default": theme.palette.background.default,
          "--bg-paper": theme.palette.background.paper,
          "--text-body": theme.palette.text.primary,
          "--text-muted": theme.palette.text.secondary,
          "--text-disabled": theme.palette.text.disabled,
          "--font": theme.typography.fontFamily,
          "--font-caption": generateFontProperty(theme.typography.caption),
          "--font-h1": generateFontProperty(theme.typography.h1),
          "--font-h2": generateFontProperty(theme.typography.h2),
          "--font-h3": generateFontProperty(theme.typography.h3),
          "--font-h4": generateFontProperty(theme.typography.h4),
          "--font-h5": generateFontProperty(theme.typography.h5),
          "--font-h6": generateFontProperty(theme.typography.h6),
          "--font-overline": generateFontProperty(theme.typography.overline),
          "--font-body-1": generateFontProperty(theme.typography.body1),
          "--font-body-2": generateFontProperty(theme.typography.body2),
          "--font-subtitle-1": generateFontProperty(theme.typography.subtitle1),
          "--font-subtitle-2": generateFontProperty(theme.typography.subtitle2),
          "--font-button": generateFontProperty(theme.typography.button),
          "--font-headline": "400 24px/32px var(--font)",
          "--font-title": "500 18px/26px var(--font)",
          "--font-display-1": "400 34px/40px var(--font)",
          "--font-display-2": "400 45px/48px var(--font)",
          "--font-display-3": "400 56px/56px var(--font)",
          "--font-display-4": "300 112px/112px var(--font)",
          "--color-blue": "rgb(2, 96, 219)",
        },
        ...generateShadowVars(theme),
      },
    },
  })
);

const generateFontProperty = (fontObject: CSSProperties) => {
  return `${fontObject.fontWeight} ${fontObject.fontSize}/${fontObject.lineHeight} ${fontObject.fontFamily}`;
};

const generateShadowVars = (theme: Theme) => {
  return theme.shadows.reduce(function (
    result: { [x: string]: any },
    item: any,
    index: any,
    array: any
  ) {
    result[`--elevation-z${index}`] = item;
    return result;
  },
  {});
};
