import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import Loading from "components/assets/Loading";
import ChangePasswordForExpiredPassword from "components/login/ChangePasswordForExpiredPassword";
import Login from "components/login/Login";
import SetupPasswordOnFirstLogin from "components/login/SetupPasswordOnFirstLogin";
import TFAOTPScreen from "components/login/TFAOTPScreen";
import { useAuth } from "contexts/AuthContext";
import { differenceInDays } from "date-fns";
import useServerEvent from "hooks/useServerEvent";
import { useEffect } from "react";
import { Case, Default, Switch } from "react-if";
import AuthServices from "services/AuthServices";
import { SocketClientFrontend } from "services/SocketClientFrontend";

export default function AuthGuard(props: {
  children: any;
  AdminOnly?: boolean;
  NoLoginPage?: boolean;
}) {
  const auth = useAuth();

  useServerEvent(
    [`User_${auth.user?._id}`],
    (message) => {
      if (message?.data.Action === "Logout" && message?.data.newToken) {
        if (AuthServices.getToken() !== message?.data.newToken) {
          AuthServices.onLogout({
            shouldBroadcast: true,
            logoutReason: `Logout by Login from another device`,
          });
        }
      }
    },
    {
      dependency: [auth.user?._id],
    }
  );

  // useEffect(() => {
  //   // SocketClientFrontend.deInit().then(() => {
  //   //   SocketClientFrontend.init();
  //   // });
  //   // if (auth.user) {
  //   // } else {
  //   //   SocketClientFrontend.deInit();
  //   // }
  // }, [auth.user?._id]);

  if (props.NoLoginPage) {
    if (auth.user) {
      return props.children;
    }
    return null;
  }

  let isPasswordExpired = !auth.user?.LastPasswordChangeTime;
  if (auth.user?.LastPasswordChangeTime) {
    isPasswordExpired =
      differenceInDays(
        new Date(),
        new Date(auth.user?.LastPasswordChangeTime)
      ) > 90;
  }

  return (
    <>
      <Switch>
        <Case condition={!auth.isUserDataFetched}>
          <Loading />
        </Case>
        <Case condition={!!auth.OTPObject}>
          <TFAOTPScreen OTPObject={auth.OTPObject as any} />
        </Case>
        <Case condition={!auth.user}>
          <div>
            <Login noRedirect={true} />
          </div>
        </Case>
        <Case condition={isPasswordExpired}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ChangePasswordForExpiredPassword />
          </Box>
        </Case>
        <Case condition={!auth.user?.IsPasswordSetupComplete}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SetupPasswordOnFirstLogin />
          </Box>
        </Case>
        <Case condition={props.AdminOnly && !auth.user?.IsAdminUser}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Alert severity="error" sx={{ mt: 5 }}>
              This feature is only available to admin
            </Alert>
          </Box>
        </Case>
        <Default>{props.children}</Default>
      </Switch>
    </>
  );
}
