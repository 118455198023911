import Loading from "components/assets/Loading";
import { AlertUtils } from "hyper-utils";
import React from "react";

export default function OverPageLoader() {
  return (
    <div
      id="divLoading"
      style={{
        position: "fixed",
        zIndex: 5000,
        top: 0,
        left: 0,
        background: "rgba(0,0,0,0.2)",
        height: "100vh",
        width: "100vw",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </div>
  );
}

export function showLoading() {
  let divLoading = document.getElementById("divLoading");
  if (divLoading) {
    divLoading.style.display = "flex";
  }
}

export function hideLoading() {
  let divLoading = document.getElementById("divLoading");
  if (divLoading) {
    divLoading.style.display = "none";
  }
}

export function withLoadingError<A extends unknown[]>(
  callback: (...args: A) => any
) {
  return async (...args: A) => {
    try {
      showLoading();
      await callback(...args);
    } catch (ex) {
      AlertUtils.showError(ex, {});
    } finally {
      hideLoading();
    }
  };
}

export async function callWithLoadingError(
  cb: () => Promise<any>,
  options?: { ThrowError?: boolean }
) {
  try {
    showLoading();
    return await cb();
  } catch (ex) {
    AlertUtils.showError(ex, {});
    if (options?.ThrowError) throw ex;
  } finally {
    hideLoading();
  }
}
