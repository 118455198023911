import { Button } from "@mui/material";
import { closeSnackbar, SnackbarProvider } from "notistack";

interface INotiStackProviderProps {
  children: any;
}
export default function NotiStackProvider(props: INotiStackProviderProps) {
  return (
    <>
      <SnackbarProvider
        action={(snackbarId) => (
          <Button
            variant="text"
            color="inherit"
            onClick={() => closeSnackbar(snackbarId)}
          >
            Dismiss
          </Button>
        )}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {props.children}
      </SnackbarProvider>
    </>
  );
}
