import { CircularProgress, Paper } from "@mui/material";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";
import React from "react";

export default function ReactQueryLoadingError(props: {
  q: any;
  LoadingProperty?: "isLoading" | "isFetching";
}) {
  let LoadingProperty = props.LoadingProperty || "isFetching";
  if (!props.q[LoadingProperty]) {
    LoadingProperty = "isLoading";
  }
  return (
    <>
      {props.q[LoadingProperty] ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <>{props.q.isError && <ErrorPlaceHolder ex={props.q.error} />}</>
      )}
    </>
  );
}
