// ----------------------------------------------------------------------

import { Box, BoxProps, darken, lighten, useTheme } from "@mui/material";

export default function Loading({ ...other }: BoxProps) {
  const theme = useTheme();

  const colors = [
    theme.palette.primary.dark,
    theme.palette.primary.main,
    theme.palette.primary.light,
    lighten(theme.palette.primary.light, 0.3),
  ];

  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          margin: "auto",
          background: "transparent",
          display: "block",
          shapeRendering: "auto",
        }}
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="84" cy="50" r="10" fill={colors[0]}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="0.5s"
            calcMode="spline"
            keyTimes="0;1"
            values="15;0"
            keySplines="0 0.5 0.5 1"
            begin="0s"
          ></animate>
          <animate
            attributeName="fill"
            repeatCount="indefinite"
            dur="2s"
            calcMode="discrete"
            keyTimes="0;0.25;0.5;0.75;1"
            values={colors.join(";")}
            begin="0s"
          ></animate>
        </circle>
        {/* {colors.map((item) => {
          return (
            <circle key={item} cx="16" cy="50" r="10" fill={item}>
              <animate
                attributeName="r"
                repeatCount="indefinite"
                dur="2s"
                calcMode="spline"
                keyTimes="0;0.25;0.5;0.75;1"
                values="0;0;15;15;15"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                begin="0s"
              ></animate>
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="2s"
                calcMode="spline"
                keyTimes="0;0.25;0.5;0.75;1"
                values="16;16;16;50;84"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                begin="0s"
              ></animate>
            </circle>
          );
        })} */}
        <circle cx="16" cy="50" r="10" fill={colors[0]}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="2s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;15;15;15"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="0s"
          ></animate>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="2s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="50" cy="50" r="10" fill={colors[1]}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="2s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;15;15;15"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.5s"
          ></animate>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="2s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-0.5s"
          ></animate>
        </circle>
        <circle cx="84" cy="50" r="10" fill={colors[2]}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="2s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;15;15;15"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-1s"
          ></animate>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="2s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-1s"
          ></animate>
        </circle>
        <circle cx="16" cy="50" r="10" fill={colors[3]}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="2s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="0;0;15;15;15"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-1.5s"
          ></animate>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="2s"
            calcMode="spline"
            keyTimes="0;0.25;0.5;0.75;1"
            values="16;16;16;50;84"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            begin="-1.5s"
          ></animate>
        </circle>
      </svg>
    </Box>
  );
}
