import { Icon, IconProps } from "@mui/material";
import { IMaterialIcon } from "data/material-icons";
import React from "react";

/**
 * @category Frontend
 * @subcategory Misc
 * @description
 * - This removes the problem of importing icons from material-ui/icons package.
 * - This component is used to render material-font icon.
 * - You can find available icons at https://fonts.google.com/icons
 * - It will also provide auto complete suggestion for `name` prop.
 * @example
 * ```tsx
 * <RIcon name="home" />
 * ```
 * @param props - The props for the RIcon component
 * @param props.name - The name of the material-font icon to render
 * @param props.variant - The variant of the icon to render. Can be "OUTLINED" or "CONTAINED". Default is "OUTLINED".
 * @param props.rounded - If true, the icon will be rounded. Default is false.
 */
const RIcon = React.forwardRef(
  (
    props: Partial<IconProps> & {
      name: IMaterialIcon;
      variant?: "OUTLINED" | "CONTAINED";
      rounded?: boolean;
    },
    ref: any
  ) => {
    let style = { ...props.style };
    if (props.rounded) {
      style.borderRadius = 50;
    }
    return (
      <Icon
        ref={ref}
        className={
          props.variant === "OUTLINED" ? "material-symbols-outlined" : ""
        }
        {...props}
        style={style}
      >
        {props.name}
      </Icon>
    );
  }
);

export default RIcon;
