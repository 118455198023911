import { ITFAOTPObject } from "commonlib";
import { LocalStorageUtils } from "hyper-utils";
import { Store } from "pullstate";
import AuthServices from "services/AuthServices";
import { SocketClientFrontend } from "services/SocketClientFrontend";
import { IMasterClientShort } from "types/masterClient";
import { ISystemUser } from "types/system-users";

interface IAuthState {
  user:
    | (ISystemUser & {
        ClientPublicIP?: string;
      })
    | null;
  isUserDataFetched: boolean;
  selectedMasterClient: IMasterClientShort | null;
  logoutReason?: string;
  OTPObject?: ITFAOTPObject;
}

export const AuthStore = new Store<IAuthState>({
  user: null,
  isUserDataFetched: false,
  selectedMasterClient: LocalStorageUtils.lsGet("selectedMasterClient") || null,
});

AuthStore.subscribe(
  (s) => s.user,
  (currentState, c, prev) => {
    if (currentState?._id !== prev?._id) {
      SocketClientFrontend.deInit().then(() => {
        SocketClientFrontend.init();
      });
    }
  }
);

AuthStore.listenToPatches((patches) => {
  let patch = patches.find((item) =>
    item.path.includes("selectedMasterClient")
  );
  if (patch) {
    LocalStorageUtils.lsSet("selectedMasterClient", patch.value);
  }
});

export const updateUserData = (userData: ISystemUser | null) => {
  AuthStore.update((s) => {
    if (userData) {
      // Remove screen lock storage so it do not logout i
      LocalStorageUtils.lsDelete("isScreenLocked");
    }
    s.user = userData;
    s.isUserDataFetched = true;
    if (
      userData?.Access?.MasterClients &&
      userData?.Access?.MasterClients?.length > 0
    ) {
      if (s.selectedMasterClient) {
        let objMasterClient = userData?.Access?.MasterClients.find(
          (item) =>
            item.MasterClientID === s.selectedMasterClient?.MasterClientID
        );
        s.selectedMasterClient = (objMasterClient || null) as any;
      } else if (userData.Preferences?.DefaultMasterClientID) {
        let objMasterClient = userData?.Access?.MasterClients.find(
          (item) =>
            item.MasterClientID === userData.Preferences?.DefaultMasterClientID
        );
        s.selectedMasterClient = (objMasterClient || null) as any;
      } else {
        s.selectedMasterClient = userData?.Access?.MasterClients[0] as any;
      }
    } else {
      s.selectedMasterClient = null;
    }
  });
};

export function setSelectedMasterClient(
  selectedMasterClient: IMasterClientShort | null
) {
  AuthStore.update((s) => {
    s.selectedMasterClient = selectedMasterClient;
  });
}

export function logoutUser() {
  AuthServices.setToken("");
  AuthStore.update((s) => {
    s.user = null;
  });
}

export function useMasterClientID() {
  const { selectedMasterClient } = AuthStore.useState();

  return selectedMasterClient?.MasterClientID;
}

export function SetOTPObject(obj: any) {
  AuthStore.update((s) => {
    s.OTPObject = obj;
  });
}
