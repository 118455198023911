import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";
import HTextField from "components/inputs/hookInputs/HTextField";
import { AlertUtils } from "hyper-utils";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { bSdk } from "services/BackendSDKService";
import * as yup from "yup";

interface IFormType {
  Username: string;
}

interface IResetPasswordProps {
  onComplete?: () => void;
}
export default function ResetPassword(props: IResetPasswordProps) {
  const objForm = useForm<IFormType>({
    defaultValues: {
      Username: "",
    },
    resolver: yupResolver(
      yup.object({
        Username: yup.string().required("Username is required."),
      })
    ),
  });

  const action = useMutation(async (data: IFormType) => {
    let result = await bSdk.Auth_SendResetPasswordLink(data);
    if (!result.data.SentEmail && !result.data.SentSMS) {
      throw new Error(`Could not send Password link. `);
    }
    let sendVia = [];
    if (result.data.SentEmail) {
      sendVia.push(`Email ${result.data.SentEmail} `);
    }
    if (result.data.SentSMS) {
      sendVia.push(`Phone ${result.data.SentSMS} `);
    }
    AlertUtils.showSuccess(
      `Password link is sent on your ${sendVia.join(
        " and "
      )}. You can reset password by opening the link. `
    );
    console.log(
      `Password link is sent on your ${sendVia.join(
        " and "
      )}. You can reset password by opening the link. `
    );
    props.onComplete?.();
  });
  return (
    <div>
      <form
        onSubmit={objForm.handleSubmit((data) => {
          action.mutate(data);
        })}
      >
        <FormProvider {...objForm}>
          <Grid container>
            <Grid item xs={12}>
              <HTextField
                name="Username"
                componentProps={{
                  label: "Username",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton loading={action.isLoading} type="submit">
                Submit
              </LoadingButton>
              {action.isError && <ErrorPlaceHolder ex={action.error} />}
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </div>
  );
}
