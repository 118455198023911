import { Paper } from "@mui/material";
import ChangePassword from "components/my-account/ChangePassword";
import { refetchQuery } from "services/ReactQueryClient";

interface IFormType {
  Username: string;
}

interface ISetupPasswordOnFirstLoginProps {
  onComplete?: () => void;
}
export default function SetupPasswordOnFirstLogin(
  props: ISetupPasswordOnFirstLoginProps
) {
  return (
    <div className="flex-center" style={{ height: "100vh" }}>
      <Paper style={{ padding: 15 }}>
        <div>
          Please change the password to continue accessing the website.{" "}
          <div>Password must meet following criteria:</div>
          <ul>
            <li>
              <strong>Minimum Length</strong>: At least 12 characters long.
            </li>
            <li>
              <strong>Letters</strong>: Must include both uppercase and
              lowercase letters.
            </li>
            <li>
              <strong>Numbers and Special Characters</strong>: Must include at
              least one number and one special character (e.g., !, @, #, $, %,
              ^, &, *).
            </li>
          </ul>
        </div>
        <ChangePassword
          onComplete={() => {
            refetchQuery("auth-query");
          }}
        />
      </Paper>
    </div>
  );
}
