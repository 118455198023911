import config from "config";
import { IMasterClientSO, MasterClientIO } from "types/masterClient";
import { IFormInputs } from "types/user";
import { setWindow } from "utils/winUtils";
import AuthServices from "./AuthServices";
import HttpService from "./HttpService";

class ApiService {
  static async login(so: IFormInputs) {
    const url = `${config.ROOT_URL}/api/auth/login`;
    const response = await HttpService.post(url, { ...so });
    return response.data;
  }
  static async getUserAndToken() {
    const url = `${config.ROOT_URL}/api/auth/decodeToken`;
    const headers = {
      Authorization: AuthServices.getToken(),
    };
    const response = await HttpService.get(url, headers);
    return response.data;
  }

  // Master client
  static async getMasterClient(so: IMasterClientSO) {
    let url = `${config.ROOT_URL}/api/master-client?`;
    if (so) {
      url += so.limit ? `&limit=${so.limit}` : "";
      url += so.offset ? `&offset=${so.offset}` : "";
      url += so.sort ? `&sort=${so.sort}` : "";
      url += so.sortOrder ? `&sortOrder=${so.sortOrder}` : "";
    }
    const headers = {
      Authorization: AuthServices.getToken(),
    };
    const response = await HttpService.get(url, headers);
    return response.data;
  }

  static async getMasterClientById(id: string) {
    let url = `${config.ROOT_URL}/api/master-client/${id}`;

    const headers = {
      Authorization: AuthServices.getToken(),
    };
    const response = await HttpService.get(url, headers);
    return response.data;
  }

  static async addMasterClient(io: MasterClientIO) {
    const url = `${config.ROOT_URL}/api/master-client`;
    const headers = {
      Authorization: AuthServices.getToken(),
    };
    const response = await HttpService.post(url, { ...io }, headers);
    return response.data;
  }

  static async editMasterClient(id: string, io: MasterClientIO) {
    const url = `${config.ROOT_URL}/api/master-client/${id}`;
    const headers = {
      Authorization: AuthServices.getToken(),
    };
    const response = await HttpService.put(url, { ...io }, headers);
    return response.data;
  }

  static async addOrUpdateTourismTaxRevenueFromReceipts(io: {
    receiptFiles: string[];
    SubClientID: string;
  }) {
    
  }
}

setWindow("ApiService", ApiService);

export default ApiService;
