import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";
import { showDialog } from "common/modal/ConfirmModal";
import HTextField from "components/inputs/hookInputs/HTextField";
import config from "config";
import useObjectState from "hooks/useObjectState";
import IPContainer from "layouts/common/IPBox";
import { useRouter } from "next/router";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import ApiService from "services/ApiService";
import AuthServices from "services/AuthServices";
import { clearAllCaches } from "services/ReactQueryClient";
import { AuthStore, SetOTPObject, updateUserData } from "stores/authStore";
import { IFormInputs } from "types/user";
import { getWindow } from "utils/winUtils";
import * as yup from "yup";
import ResetPassword from "./ResetPassword";

const schema = yup
  .object({
    Username: yup.string().required("Username is Required"),
    Password: yup.string().required("Password is Required"),
  })
  .required();

export default function Login({ noRedirect }: { noRedirect?: boolean }) {
  const authStore = AuthStore.useState();
  const actionLogin = useMutation(async (data: IFormInputs) => {
    const result = await ApiService.login(data);
    return result.data;
  });
  const router = useRouter();
  const { state, setState } = useObjectState({
    capsLock: false,
  });

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: IFormInputs) => {
    AuthServices.removeToken();
    clearAllCaches();
    const loginResponse = await actionLogin.mutateAsync(data);
    if (loginResponse.OTPToken) {
      SetOTPObject(loginResponse);
    } else {
      AuthServices.setToken(loginResponse.token);
      updateUserData(loginResponse.tokenData);
      if (getWindow()?.loginChannel) {
        getWindow().loginChannel.postMessage(loginResponse);
      }

      if (!noRedirect) {
        router.push("/");
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }} alignItems="unset">
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        lg={9}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random/?nature)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        lg={3}
        component={Paper}
        elevation={6}
        // square
      >
        <FormProvider {...methods}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar src="/images/logo.png" sx={{ width: 150, height: 150 }} />
            <Typography component="h1" variant="h5" sx={{ my: 2 }}>
              Login
            </Typography>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div style={{ position: "relative" }}>
                      <HTextField
                        componentProps={{
                          autoFocus: true,
                          label: "Username",
                          onKeyDown: (e) => {
                            let caps = e.getModifierState("CapsLock");
                            if (e.key === "CapsLock") {
                              setState({ capsLock: !caps });
                            } else {
                              setState({ capsLock: caps });
                            }
                          },
                        }}
                        name="Username"
                      />
                      <div
                        className="text-bold text-blue"
                        style={{
                          position: "absolute",
                          top: "23%",
                          right: "20px",
                          color: "var(--primary)",
                        }}
                      >
                        (Case Sensitive)
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ position: "relative" }}>
                      <HTextField
                        componentProps={{
                          label: "Password",
                          type: "password",
                          onKeyDown: (e) => {
                            let caps = e.getModifierState("CapsLock");
                            if (e.key === "CapsLock") {
                              setState({ capsLock: !caps });
                            } else {
                              setState({ capsLock: caps });
                            }
                          },
                        }}
                        name="Password"
                      />
                      <div
                        className="text-bold text-blue"
                        style={{
                          position: "absolute",
                          top: "23%",
                          right: "20px",
                          color: "var(--primary)",
                        }}
                      >
                        (Case Sensitive)
                      </div>
                    </div>
                    {state.capsLock && (
                      <Alert severity="warning">Caps Lock is on</Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {authStore.logoutReason && (
                      <Alert severity="info">
                        Last Session: <b>{authStore.logoutReason}</b>
                      </Alert>
                    )}
                  </Grid>

                  {config.IS_DEVELOPMENT && (
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        onClick={() => {
                          onSubmit({
                            Username: "admin",
                            Password: "Test1!TestTes",
                          });
                        }}
                      >
                        SA
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={() => {
                          onSubmit({
                            Username: "hemant",
                            Password: "Test1!TestTes",
                          });
                        }}
                      >
                        OA
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={() => {
                          onSubmit({
                            Username: "master1",
                            Password: "Test1!TestTes",
                          });
                        }}
                      >
                        MA
                      </Button>{" "}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {actionLogin.isError && (
                      <ErrorPlaceHolder ex={actionLogin.error} />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      loading={actionLogin.isLoading}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Login
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                let modal = showDialog({
                  title: "Reset Password",
                  body: (
                    <ResetPassword
                      onComplete={() => {
                        modal.toggle(false);
                      }}
                    />
                  ),
                  dialogProps: {
                    maxWidth: "xs",
                  },
                });
              }}
              variant="text"
            >
              Forgot Password?
            </Button>
          </div>
        </FormProvider>

        <Grid item xs={12} style={{ flexGrow: 1 }}>
          <IPContainer />
        </Grid>
      </Grid>
    </Grid>
  );
}
